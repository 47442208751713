import { Chip, Typography } from '@material-ui/core'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Photo } from '@material-ui/icons'
import { Avatar, FlexBox } from '../../../../custom-components'

import './PurchaseProductName.less'

interface Props {
  imageUrl?: string
  typeClassName?: string
  typeName?: string
  code?: string
  isNew?: boolean
  name: string
  style?: CSSProperties
  warningDeal?: boolean
  warningDealPrice?: boolean
}

const PurchaseProductName: React.FC<Props> = (props: Props) => {
  const { imageUrl, typeClassName, typeName, code, isNew, name, style, warningDeal, warningDealPrice } = props

  return (
    <FlexBox style={style} alignItems="flex-start">
      <Avatar variant="rounded" src={imageUrl} objectFit="contain">
        <Photo />
      </Avatar>
      <FlexBox flexDirection="column" ml={1} className="purchase-product-header">
        <FlexBox gridGap={8}>
          {typeName && (
            <Typography variant="body2" className={typeClassName}>
              {typeName}
            </Typography>
          )}
          <Typography variant="body2" className="purchase-product-header__code">
            {code}
          </Typography>
          {isNew && <Chip label="ใหม่" style={{ backgroundColor: '#FF5F52' }} size="small" />}
        </FlexBox>
        <Typography variant="body2" className="purchase-product-header__name">
          {name}
        </Typography>
        {warningDeal && (
          <Typography variant="body2" color="secondary">
            ผู้ขายที่เลือกไม่ใช่ผู้ขายที่ดีลไว้ล่าสุด
          </Typography>
        )}
        {warningDealPrice && (
          <Typography variant="body2" color="error">
            ราคาไม่ตรงกับที่ตกลงกันล่าสุด
          </Typography>
        )}
      </FlexBox>
    </FlexBox>
  )
}

export default PurchaseProductName
