import { IPaginationInfo, ITableData } from '../../../types/pagination'
import { ICustomer, ICustomerContact } from '../customer'
import {
  ICustomerDB,
  IPurchaseOrder,
  IPurchaseOrderProduct,
  IQuotation,
  IQuotationProduct,
  ISaleOrderProduct,
  IVendor,
  IVendorContactDB,
  IVendorDB,
} from '../interfaces'
import { ISaleOrder } from '../sale/sale-types'
import { BaseData, TableDataQueryParams, UserActivityNote } from '../types'
import { ProductPrice, ProductUserAcivity } from './types'

export interface ProductWithROPInfo extends ProductWithROPData {
  averageSaleAmount: number
  safetyStock: number
  reorderPoint: number
  maxStockAmount: number
  purchaseAmount: number
  haveToPurchase: boolean
}

export interface ProductWithROPData {
  id: number
  code: string
  name: string
  imageUrl?: string
  grade?: string
  stockAmount: number
  saleAmount: number
  leadTime: number
  safetyStockPolicy: number
}

export interface IProductTransaction {
  id: number
  typeId: number
  typeName: string
  productId: number
  productImageUrl?: string
  productCode: string
  productNameLocal: string
  amount: number
  remainingStockAmount: number
  recCreatedAt: Date
  recCreatedBy: string
}

export interface IProductTableData_Data {
  id: number
  code: string
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  warrantyPeriodDay?: number
  stockAmount: number
  reservedAmount: number
  claimedAmount: number
  incomingAmount: number
  soReservedAmount: number
  quotationProductAmount: number
  withdrawnAmount: number
  grade?: string
  saleAmount: number
  leadTime: number
  safetyStockPolicy: number
  verifiedBy?: string
  requestChangedData?: Partial<IProductWithFKColumns>
  businessUnit?: BaseData
  category?: BaseData
  subCategory?: BaseData
  storage?: BaseData
  type?: BaseData
  dealerPrice?: number
  brand?: string
  color?: string
  material?: string
  widthInMm?: number
  heightInMm?: number
  depthInMm?: number
  weightInGram?: number
  warrantyCondition?: string
  karajicMatrix?: BaseData

  dataScore: number
}

export interface IProductTableData_SearchParams {
  name?: string
  code?: string
  mode?: string
  gradeList?: string[]
  typeIds?: number[]
  isPendingVerified?: string
  isRequestChangedData?: string
}

export type IProductTableData = {
  data: IProductTableData_Data[]
  paginationInfo: IPaginationInfo
  searchParams: IProductTableData_SearchParams
}

export interface GetProductTableDataSearchParams {
  mode?: string
  name?: string
  code?: string
  gradeList?: string
  isPendingVerified?: string
  isRequestChangedData?: string
}

export type GetProductTableDataRequestQueryParams = TableDataQueryParams & GetProductTableDataSearchParams

export type GetProductROPTableDataRequestQueryParams = TableDataQueryParams & GetProductROPTableDataSearchParams

export interface GetProductROPTableDataSearchParams {
  name?: string
  code?: string
  gradeList?: string
}

export interface IProductROPTableData_Data {
  id: number
  code: string
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  grade?: string
  stockAmount: number
  saleAmount: number
  leadTime: number
  safetyStockPolicy: number
  soReservedAmount: number
  quotationProductAmount: number
  reservedAmount: number

  averageSaleAmount: number
  safetyStock: number
  reorderPoint: number
  maxStockAmount: number
  purchaseAmount: number
  haveToPurchase: boolean
  estimateOutOfStockDate?: Date
  readyToSellAmount: number
  incomingAmount: number
  type?: BaseData

  // calculated data
  createQTWithInsufficientStockRate?: number
  createSOWithInsufficientStockRate?: number
}

export interface IProductVendorDealPriceHistories {
  id: number
  productId: number
  price: number
  recCreatedAt: Date
  recCreatedBy: string
  vendor: Pick<IVendor, 'id' | 'code' | 'name' | 'phoneNumber' | 'email' | 'profileImageUrl' | 'currency'>
}

export interface IProductROPTableData_SearchParams {
  name?: string
  code?: string
  gradeList?: string[]
}

export type IProductROPTableData = {
  data: IProductROPTableData_Data[]
  paginationInfo: IPaginationInfo
  searchParams: IProductROPTableData_SearchParams
}

export interface IProduct {
  id: number
  headerId: number
  code: string
  nameLocal: string
  nameEn?: string
  unitLocal: string
  unitEn?: string
  imageUrl?: string
  warrantyPeriodDay?: number
  leadTime: number
  safetyStockPolicy: number
  dealerPrice?: number
  lotDetail?: string

  stockAmount: number
  reservedAmount: number
  incomingAmount: number
  soReservedAmount: number
  claimedAmount: number
  withdrawnAmount: number

  brand?: string
  color?: string
  material?: string
  widthInMm?: number
  heightInMm?: number
  depthInMm?: number
  weightInGram?: number
  warrantyCondition?: string
  karajicMatrix?: BaseData

  grade?: string

  type: BaseData
  storage?: BaseData
  businessUnit?: BaseData
  category?: BaseData
  subCategory?: BaseData

  verifiedBy?: string
  requestChangedData?: Partial<IProductWithFKColumns>

  recDeletedAt?: Date
  recDeletedBy?: string
  vendorDealPriceHistories?: IProductVendorDealPriceHistories[]
}

export interface IProductChangeData {
  rawData: IProduct
  requestChangedData: Partial<IProduct>
}

export interface IProductWithFKColumns
  extends Omit<IProduct, 'karajicMatrix' | 'type' | 'storage' | 'businessUnit' | 'category'> {
  productTypeId: number
  businessUnitId?: number
  categoryId?: number
  subCategoryId?: number
  storageId?: number
  karajicMatrixId?: number
}

export interface RequestToUpdateProductRequestBody {
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  businessUnitId?: number | null
  categoryId?: number | null
  subCategoryId?: number | null
  warrantyPeriodDay?: number
  storageId?: number | null
  imageUrl?: string
  productImageBase64?: string
  leadTime?: number
  safetyStockPolicy?: number
  dealerPrice?: number
  brand?: string
  color?: string
  material?: string
  widthInMm?: number
  heightInMm?: number
  depthInMm?: number
  weightInGram?: number
  warrantyCondition?: string
  karajicMatrixId?: number | null
}

export interface IProductProfile extends IProduct {
  saleAmount: number
  withdrawnAmount: number

  prices: ProductPrice[]
  activities: ProductUserAcivity[]
  dataScore: number

  reservedSaleOrders: (Pick<ISaleOrder, 'id' | 'code' | 'status' | 'dueDate' | 'recCreatedAt'> &
    Pick<
      ISaleOrderProduct,
      'amount' | 'price' | 'productTotal' | 'shippedAmount' | 'reservedAmount' | 'pendingDeliverAmount'
    > & {
      customer: Pick<ICustomer, 'id' | 'nameLocal'>
      customerContact: Pick<ICustomerContact, 'id' | 'nameLocal' | 'nicknameLocal'>
    })[]
  pendingReceivedPurchaseOrders: (Pick<
    IPurchaseOrder,
    'id' | 'code' | 'status' | 'vendorShippingDate' | 'recCreatedAt'
  > &
    Pick<
      IPurchaseOrderProduct,
      'amount' | 'price' | 'productTotal' | 'receivedAmount' | 'pendingReceivedAmount' | 'incomingAmount'
    > & {
      vendor: Pick<IVendorDB, 'id' | 'name'>
      vendorContact: Pick<IVendorContactDB, 'id' | 'name'>
    })[]

  doneSaleOrders: (Pick<ISaleOrder, 'id' | 'code' | 'status' | 'recCreatedAt'> &
    Pick<ISaleOrderProduct, 'amount' | 'price' | 'productTotal'> & {
      customer: Pick<ICustomerDB, 'id' | 'nameLocal'>
    })[]

  doneDeliverSaleOrders: (Pick<ISaleOrder, 'id' | 'code'> &
    Pick<ISaleOrderProduct, 'amount' | 'price' | 'productTotal'> & {
      customer: Pick<ICustomerDB, 'id' | 'code' | 'nameLocal'>
    })[]

  donePurchaseOrders: (Pick<IPurchaseOrder, 'id' | 'code' | 'status' | 'recCreatedAt'> &
    Pick<IPurchaseOrderProduct, 'amount' | 'price' | 'productTotal'> & {
      vendor: Pick<IVendorDB, 'id' | 'name'>
    })[]

  pairedProducts: Pick<IProduct, 'id' | 'code' | 'nameLocal' | 'imageUrl'>[]
  quotationHistory: (Pick<IQuotation, 'id' | 'code' | 'status'> &
    Pick<IQuotationProduct, 'amount' | 'price' | 'productTotal'> & {
      customer: Pick<ICustomer, 'id' | 'nameLocal'>
      customerContact?: Pick<ICustomerContact, 'id' | 'nameLocal' | 'nicknameLocal'>
    })[]

  seasonalData: IProductProfile_SeasonalData[]
  topCoSalesProduct: ITopCOSalesProduct[]
  summaryProduct: IProductProfile_Summary
  vendorDealPriceHistories?: IProductVendorDealPriceHistories[]
}

export interface IProductProfile_SeasonalData {
  time: number
  salesTotal: number
}

export interface ITopCOSalesProduct {
  id: number
  code: string
  nameLocal: string
  totaAmount: number
  averagePrice: number
  salesProductTotal: number
  imageUrl?: string
  unitLocal?: string
}

export interface IProductProfile_Summary {
  grossProfitMarginPercent: number
  turnOverRateRemainningAllProduct: number
  averageTurnOverDay: number
}

export interface IProductAnalyticReportTableData_SearchParams {
  startDateTime: string
  endDateTime: string
  grade?: string
  karajicMatrixIds?: number[]
  isDeadStock?: boolean
  contributionOp?: number
}

export interface IProductAnalyticReportTableData {
  data: IProductAnalyticReportTableData_Data[]
  paginationInfo: IPaginationInfo
  searchParams: IProductAnalyticReportTableData_SearchParams
}

export interface IProductAnalyticReportTableData_Data {
  id: number
  code: string
  nameLocal: string
  grade?: string
  imageUrl?: string
  karajicMatrix?: BaseData
  saleOrderAmount: number
  salesTotal: number
  salesTotalPercent: number
  grossProfit: number
  grossProfitMarginPercent: number
  snapshotStockValue: number
  turnOverRate: number
  contribution: number
  contributionPercent: number
  isDeadStock: boolean
}

export type ProductAnalyticTableDataQueryParams = TableDataQueryParams & {
  startDateTime: string
  endDateTime: string
  grade?: string
  karajicMatrixIds?: string
  isDeadStock?: string
  contributionOp?: string
}

export type UpdateProductStockAmountRequestBody = UserActivityNote & {
  stockAmount: number
}

export type IProductMustToPurchaseTableData = ITableData<
  ProductMustToPurchase.Data,
  ProductMustToPurchase.SearchParams,
  ProductMustToPurchase.Summary
>

export namespace ProductMustToPurchase {
  export interface Data {
    id: number
    code: string
    nameLocal: string
    imageUrl?: string
    grade?: string
    estimateOutOfStockDate?: Date
    actualPurchaseAmount: number
    averageSaleAmount: number
    cost: number
    currency: BaseData
    purchaseTotal: number
    lastedVendorBuy?: Pick<IVendor, 'id' | 'code' | 'name'>
  }

  export interface SearchParams {
    nameLocal?: string
    code?: string
    grades?: string[]
    outOfStockDate?: Date
    vendorName?: string
  }

  export interface Summary {}
}

export type ProductMustToPurchaseQueryParams = TableDataQueryParams & GetProductMustToPurchaseSearchParams

export interface GetProductMustToPurchaseSearchParams {
  nameLocal?: string
  code?: string
  grades?: string
  outOfStockDate?: string
  vendorName?: string
}
