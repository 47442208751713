import { CreatedInfo } from '..'
import { errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import {
  FetchVendor,
  VendorCreateAddressRequestBody,
  VendorCreateBankAccountRequestBody,
  VendorCreateContactRequestBody,
  VendorCreateRequest,
  VendorProfile,
  VendorUpdateAttachmentRequestBody,
  VendorVerificationResult,
  VendorVerifyRequestBody,
  VendorUpdateRequestBody,
  CreateVendorProductDealPriceRequest,
} from './vendor-types'

export const fetchVendors = errorHandler<FetchVendor[]>(async () => {
  return smartAuthAPI.get('/purchase/v1/vendors/fetch')
})

export const createVendor = errorHandler<CreatedInfo>((request: VendorCreateRequest) => {
  return smartAuthAPI.post('/purchase/v1/vendors/create', request)
})

export const updateVendor = errorSingleHandler<VendorProfile>(
  async (vendorId: number, request: VendorUpdateRequestBody) => {
    return smartAuthAPI.put(`/purchase/v1/vendors/${vendorId}/update`, request)
  },
)

export const getVendorProfile = errorSingleHandler<VendorProfile>(async (vendorId: number) => {
  return smartAuthAPI.get(`/purchase/v1/vendors/${vendorId}/profile`)
})

export const verifyVendor = errorHandler<VendorVerificationResult>((request: VendorVerifyRequestBody) => {
  return smartAuthAPI.post('/purchase/v1/vendors/verify', request)
})

export const createVendorContact = errorSingleHandler<CreatedInfo>(
  (vendorId: number, request: VendorCreateContactRequestBody) => {
    return smartAuthAPI.post(`/purchase/v1/vendors/${vendorId}/contacts/create`, request)
  },
)

export const createVendorAddress = errorSingleHandler<CreatedInfo>(
  (vendorId: number, request: VendorCreateAddressRequestBody) => {
    return smartAuthAPI.post(`/purchase/v1/vendors/${vendorId}/addresses/create`, request)
  },
)

export const createVendorBankAccount = errorSingleHandler<CreatedInfo>(
  (vendorId: number, request: VendorCreateBankAccountRequestBody) => {
    return smartAuthAPI.post(`/purchase/v1/vendors/${vendorId}/bank-accounts/create`, request)
  },
)

export const updateVendorAttachments = errorSingleHandler<CreatedInfo>(
  (vendorId: number, request: VendorUpdateAttachmentRequestBody) => {
    return smartAuthAPI.put(`/purchase/v1/vendors/${vendorId}/attachments/update`, request)
  },
)

export const createVendorProductDealPrice = async (
  productId: number,
  vendorId: number,
  request: CreateVendorProductDealPriceRequest,
) => {
  return smartAuthAPI.post(`/api/v2/products/${productId}/vendor/${vendorId}/deal-price`, request)
}
