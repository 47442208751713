import axios from 'axios'
import { getToken } from '../../functions'

export const smartAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

const smartAuthAPI = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'x-auth-token': getToken(),
  },
  timeout: 90000, // 1.5 mins
})

smartAuthAPI.interceptors.response.use(
  (response) => {
    // Return response if it is successful
    return response
  },
  async (error) => {
    console.log('error', error)
    alert(error?.message)
    throw error
  },
)

export default smartAuthAPI
