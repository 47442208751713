import { Box, Chip, IconButton, Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import { Delete, Edit, Photo } from '@material-ui/icons'
import numeral from 'numeral'
import React, { useContext, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Avatar, FlexBox, SubTopic, Table } from '../../../custom-components'
import { PurchaseProductType } from '../../../enums'
import { formatNumber } from '../../../functions/number-utils'
import PurchaseProductCreateDialog from '../../purchase-requisitions/create/PurchaseProductCreateDialog'
import { PurchaseProduct } from '../../purchase-requisitions/create/PurchaseProductSelector/PurchaseProductSelector'
import { PurchaseOrderCreatePageContext } from './PurchaseOrderCreatePage'
import { PurchaseOrderProductDialog } from './PurchaseOrderProductSelector/PurchaseOrderProductDialog'
import {
  PurchaseOrderProduct,
  PurchaseOrderProductSelectorFormValues,
} from './PurchaseOrderProductSelector/PurchaseOrderProductSelector'

import './SelectedPurchaseOrderProductTable.less'
import PurchaseOrderCreateFormValues from './PurchaseOrderCreateFormValues'

interface SelectedPurchaseOrderProductTableData extends PurchaseOrderProduct {
  id: number
  isNew: boolean
}

const SelectedPurchaseOrderProductTable: React.FC = () => {
  // hook form
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<PurchaseOrderProductSelectorFormValues>()

  const { watch: watchPOCreateForm } = useFormContext<PurchaseOrderCreateFormValues>()
  const { vendor } = watchPOCreateForm()

  // context
  const { purchaseOrderCreate } = useContext(PurchaseOrderCreatePageContext)
  const { products } = purchaseOrderCreate
  const existingProductCodeList = products.filter((product) => !!product.code).map((product) => product.code!)
  // data
  const [openProductCreationDialog, setOpenProductCreationDialog] = useState(false)
  const [openPurchaseOrderProductDialog, setOpenPurchaseOrderProductDialog] = useState(false)
  const [product, setProduct] = useState<PurchaseOrderProduct & { index: number }>()
  const { products: selectedProducts } = watch()
  const parserProducts: SelectedPurchaseOrderProductTableData[] = selectedProducts.map((product) => {
    return {
      ...product,
      id: product.id || -1,
      isNew: !product.id,
    }
  })
  const productTotal = selectedProducts.reduce<number>((sum, product) => {
    return sum + product.price! * product.amount
  }, 0)

  // handlers
  const handleRemoveProduct = (index: number) => {
    selectedProducts.splice(index, 1)
    setValue('products', selectedProducts)
  }

  const handleEditProduct = (index: number, isNew: boolean) => {
    setProduct({ ...selectedProducts[index], index })
    if (isNew) {
      setOpenProductCreationDialog(true)
    } else {
      setOpenPurchaseOrderProductDialog(true)
    }
  }

  const handleSubmitNewPurchaseProduct = (_product: PurchaseProduct) => {
    const castProduct = _product as PurchaseOrderProduct
    if (product) {
      selectedProducts[(castProduct as PurchaseOrderProduct & { index: number }).index] = castProduct
      setValue('products', selectedProducts)
    } else {
      setValue('products', [...selectedProducts, castProduct])
    }
    setProduct(undefined)
  }

  const handleSubmitPurchaseProduct = (_product: PurchaseOrderProduct) => {
    selectedProducts[(_product as PurchaseOrderProduct & { index: number }).index] = _product
    setValue('products', selectedProducts)
  }

  return (
    <Paper className="flex flex-col">
      {openProductCreationDialog && (
        <PurchaseProductCreateDialog
          open={openProductCreationDialog}
          onClose={() => {
            setOpenProductCreationDialog(false)
            setProduct(undefined)
          }}
          onDialogSubmit={handleSubmitNewPurchaseProduct}
          product={product}
          options={{ existingProductCodeList, currency: vendor?.currency?.name ?? 'บาท', isPriceRequired: true }}
        />
      )}
      {openPurchaseOrderProductDialog && (
        <PurchaseOrderProductDialog
          onDialogSubmit={handleSubmitPurchaseProduct}
          product={product as PurchaseOrderProduct}
          options={{ currency: vendor?.currency?.name ?? 'บาท' }}
          vendorId={vendor?.id}
          open={openPurchaseOrderProductDialog}
          onClose={() => {
            setOpenPurchaseOrderProductDialog(false)
            setProduct(undefined)
          }}
        />
      )}
      <FlexBox justifyContent="space-between" alignItems="center" className="pb-6">
        <SubTopic>สินค้า/บริการ ที่เลือก</SubTopic>
        {/* <Button
          variant="contained"
          color="secondary"
          startIcon={<AddCircle />}
          onClick={() => {
            setOpenProductCreationDialog((prev) => !prev)
          }}
        >
          เพิ่มสินค้า/บริการ ที่ไม่เคยมีในระบบ
        </Button> */}
      </FlexBox>
      <Table<SelectedPurchaseOrderProductTableData>
        size="small"
        color="primary.dark"
        columns={[
          {
            name: 'ลำดับ',
            fixedWidth: 72,
            render: (_, index) => (
              <FlexBox justifyContent="space-around">
                <Typography color="textSecondary" variant="body2">
                  {index + 1}
                </Typography>
              </FlexBox>
            ),
          },
          {
            name: 'ชื่อสินค้า/บริการ',
            fixedWidth: 417,
            render: ({ imageUrl, name, code, isNew, type, typeId, price, latestVendorDealPrice }) => {
              const hasVendorAndProductHasDeal = !!vendor?.id && !!latestVendorDealPrice?.vendor.id
              const matchVendor = hasVendorAndProductHasDeal && vendor?.id === latestVendorDealPrice?.vendor.id
              const warningDeal = hasVendorAndProductHasDeal ? !matchVendor : false
              const warningDealPrice =
                hasVendorAndProductHasDeal && matchVendor && !!price ? latestVendorDealPrice?.price !== price : false

              const typeClassName =
                (type && type?.id === PurchaseProductType.Service) || typeId === PurchaseProductType.Service
                  ? 'purchase-product-header__type--service'
                  : 'purchase-product-header__type--product'
              let typeName = 'บริการ'

              if (!isNew) {
                typeName = type.name
              } else if (typeId !== PurchaseProductType.Service) {
                typeName = 'สินค้า'
              }

              return (
                <FlexBox alignItems="flex-start">
                  <Avatar variant="rounded" src={imageUrl} objectFit="contain">
                    <Photo />
                  </Avatar>
                  <FlexBox flexDirection="column" ml={1} className="purchase-product-header">
                    <FlexBox gridGap={8}>
                      <Typography variant="body2" className={typeClassName}>
                        {typeName}
                      </Typography>
                      <Typography variant="body2" className="purchase-product-header__code">
                        {code}
                      </Typography>
                      {isNew && <Chip label="ใหม่" style={{ backgroundColor: '#FF5F52' }} size="small" />}
                    </FlexBox>
                    <Typography variant="body2" className="purchase-product-header__name">
                      {name}
                    </Typography>
                    {warningDeal && (
                      <Typography variant="body2" color="secondary">
                        ผู้ขายที่เลือกไม่ใช่ผู้ขายที่ดีลไว้ล่าสุด
                      </Typography>
                    )}
                    {warningDealPrice && (
                      <Typography variant="body2" color="error">
                        ราคาไม่ตรงกับที่ตกลงกันล่าสุด
                      </Typography>
                    )}
                  </FlexBox>
                </FlexBox>
              )
            },
          },
          {
            name: 'ราคาที่เราขายต่อหน่วย',
            fixedWidth: 190,
            render: ({ salePrice }) => (!!salePrice ? formatNumber(salePrice) : '-'),
          },
          {
            name: 'จำนวน',
            fixedWidth: 235,
            numeric: true,
            format: '0,0',
            dataIndex: 'amount',
          },
          {
            name: 'ราคาต่อหน่วย',
            fixedWidth: 320,
            format: '0,0.00',
            align: 'right',
            render: ({ price, salePrice }) => {
              if (!price) return '-'
              return (
                <FlexBox flexDirection="column">
                  <span>{formatNumber(price)}</span>
                  {!!salePrice && price > salePrice && (
                    <Box color="warning.main" clone>
                      <Typography variant="caption">ราคาเกินจากราคาขาย</Typography>
                    </Box>
                  )}
                </FlexBox>
              )
            },
          },
          {
            name: 'ราคารวม',
            fixedWidth: 235,
            numeric: true,
            format: '0,0.00',
            render: ({ price, amount }) => price && amount && price * amount,
          },
          {
            name: '',
            fixedWidth: 56,
            render: ({ isNew }, index) => {
              return (
                <IconButton onClick={() => handleEditProduct(index, isNew)} style={{ padding: '0px' }}>
                  <Edit />
                </IconButton>
              )
            },
          },
          {
            name: '',
            fixedWidth: 56,
            render: (_, index) => (
              <IconButton onClick={() => handleRemoveProduct(index)} style={{ padding: '0px' }}>
                <Delete color="inherit" />
              </IconButton>
            ),
          },
        ]}
        data={parserProducts || []}
        cleanSpace
        postCells={
          <TableRow>
            <TableCell colSpan={4} />
            <TableCell align="right">รวมเงิน</TableCell>
            <TableCell align="right">
              <Typography color={!!productTotal ? 'textPrimary' : 'textSecondary'} variant="body2">
                {!!productTotal ? numeral(productTotal).format('0,0.00') : 'คำนวณไม่ได้'}
              </Typography>
            </TableCell>
          </TableRow>
        }
        pagination={false}
      />
      {!!errors.products && <Typography color="error">{errors.products.message}</Typography>}
    </Paper>
  )
}

export default SelectedPurchaseOrderProductTable
