// todo: refactor this with interface
export enum PermissionEnum {
  // HR
  HR_User_Read = 10001,
  HR_User_Write = 10101,
  HR_User_Delete = 10102,

  // Warehouse
  Warehouse_Product_Read = 20001,
  Warehouse_Product_Report = 20002,
  Warehouse_Product_Write = 20101,
  Warehouse_Product_Delete = 20102,
  Warehouse_Product_StockManage = 20103,
  Warehouse_Product_Review = 20104,
  Warehouse_Task_Read = 21001,
  Warehouse_Task_Write = 21101,
  Warehouse_WithdrawalTicket_Read = 22001,
  Warehouse_WithdrawalTicket_Write = 22101,
  Warehouse_WithdrawalTicket_Cancel = 22102,
  Warehouse_WithdrawalTicket_Review = 22103,
  Warehouse_WithdrawalTicket_ForcedClose = 22104,

  // Finance Receipt
  Finance_Document_Read = 30001,
  Finance_Document_Write = 30101,
  Finance_Document_Cancel = 30102,
  Finance_SOPayment_Read = 31001,
  Finance_SOPayment_Write = 31101,
  Finance_SOPayment_Review = 31102,
  Finance_SOPayment_VerifyStatement = 31103,
  Finance_SOPayment_Cancel = 31104,
  Finance_Task_Read = 32001,
  Finance_Task_Write = 32101,

  Finance_Follow_Debt_Read = 33000,
  Finance_Follow_Debt_Write = 33001,

  // Finance Pay
  FinancePay_PaymentRequisition_Read = 40001,
  FinancePay_PaymentRequisition_Write = 40101,
  FinancePay_PaymentRequisition_Cancel = 40102,
  FinancePay_PaymentRequisition_Review = 40103,
  FinancePay_RefundRequisition_Read = 41001,
  FinancePay_RefundRequisition_Write = 41101,
  FinancePay_RefundRequisition_Review = 41102,
  FinancePay_RefundRequisition_Cancel = 41103,

  // Sales
  Sales_Customer_Read = 50001,
  Sales_Customer_Write = 50101,
  Sales_Customer_Delete = 50102,
  Sales_Customer_BlacklistManage = 50103,
  Sales_Customer_Update = 50104,

  Sales_Quotation_Read = 51001,
  Sales_Quotation_Report = 51002,
  Sales_Quotation_Write = 51101,
  Sales_Quotation_Cancel = 51102,
  Sales_Quotation_ReviewData = 51103,

  Sales_SO_Read = 52001,
  Sales_SO_Report = 52002,
  Sales_SO_Pricing = 52003,
  Sales_SO_Write = 52101,
  Sales_SO_Cancel = 52102,
  Sales_Request_To_Cancel = 52103,

  Sales_GoalAligntment_Read = 53001,
  Sales_GoalAligntment_Write = 53101,

  Sales_Profit_Read = 54001,

  Sales_Product_Cost_Read = 54002,
  Sales_Product_Cost_Write = 54101,
  Sales_Follow_Case_Customer_Read = 55001,
  Sales_Follow_Case_Customer_Write = 55101,
  Sales_Transport_Read = 55002,

  // Purchase
  Purchase_PR_Read = 60001,
  Purchase_PR_Write = 60101,
  Purchase_PR_Cancel = 60102,
  Purchase_PR_Review = 60103,

  Purchase_PO_Read = 61001,
  Purchase_PO_Pricing = 61002,
  Purchase_PO_Write = 61101,
  Purchase_PO_Cancel = 61102,
  Purchase_PO_Review = 61103,
  Purchase_PO_CallLeader = 61104,
  Purchase_PO_MarkUnCancelable = 61105,

  Purchase_Vendor_Read = 62001,
  Purchase_Vendor_Write = 62101,
  Purchase_Vendor_Review = 62102,
  Purchase_Vendor_Delete = 62103,

  Purchase_Task_Read = 63001,
  Purchase_Task_Write = 63101,

  // Transport
  Service_TransportTask_Read = 70001,
  Service_TransportTask_Report = 70002,
  Service_TransportTask_Write = 70101,
  Service_TransportTask_Cancel = 70102,

  // Customer Requisition
  Service_CustomerRequisition_Read = 71001,
  Service_CustomerRequisition_Write = 71101,
  Service_CustomerRequisition_Review = 71102,
  Service_CustomerRequisition_Cancel = 71103,

  // Marketing
  Marketing_Sales_Report = 80001,
  Marketing_Product_Report = 80002,

  // Report
  Report_Sales = 90001,
  Report_Sales_Product = 90002,
  Report_Sales_Team = 90003,
  Report_Sales_Personal = 90004,
  Report_Daily_Income = 90005,
  Report_Customer = 90006,
  Report_Purchase = 90007,
  Report_Product_Stock_Activity = 90008,
  Report_Stock_Adjustment = 90009,
  Report_Download = 90010,
  Report_History_Customer_Sales = 90011,
  Report_History_Customer_Quotation = 90012,
  Report_Product_Yearly = 90013,

  // New Finance
  Finance_Account_Read = 100001,
  Finance_Payment_Transaction_Read = 100002,
  Finance_Account_Statement_Write = 100101,
  Finance_Reconcile_Statement_Write = 100102,
}
