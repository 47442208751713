import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  InputAdornment,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

import { Photo } from '@material-ui/icons'
import { useState } from 'react'
import { Avatar, FlexBox, GeneralTextFieldForm } from '../../../../../custom-components'
import { dateTimeFormat } from '../../../../../functions'
import { usePurchaseProductHistory } from '../../../../../hooks/usePurchaseProductHistory'
import { PurchaseOrderProduct, UpsertPurchaseOrderProduct } from '../PurchaseOrderProductSelector'
import PurchaseOrderProductHistoryTable from './PurchaseOrderProductHistoryTable'
import PurchaseOrderProductOpeningTable from './PurchaseOrderProductOpeningTable'
import { useStyles } from './styles'

interface Options {
  existingProductCodeList?: string[]
  currency?: string
  vendorCountry?: string
}

interface Props extends DialogProps {
  onDialogSubmit: (product: UpsertPurchaseOrderProduct) => void
  product: PurchaseOrderProduct
  options?: Options
  vendorId?: number
}

enum PurchaseOrderProductHistoryTableType {
  History = 1,
  Opening = 2,
}

const PurchaseOrderProductDialog: React.FC<Props> = (props) => {
  const { onClose, onDialogSubmit, product, options, className, vendorId, ...otherProps } = props
  const [tableType, setTableType] = useState<PurchaseOrderProductHistoryTableType>(
    PurchaseOrderProductHistoryTableType.History,
  )

  const purchaseProductHistory = usePurchaseProductHistory(product?.id)

  const {
    control,
    watch,
    setError,
    trigger,
    formState: { errors },
  } = useForm<UpsertPurchaseOrderProduct>({
    defaultValues: product,
  })

  const hasVendorDealPrice = !!product.latestVendorDealPrice

  const hasVendorAndProductHasDeal = !!vendorId && !!product.latestVendorDealPrice?.vendor.id
  const matchVendor = hasVendorAndProductHasDeal && vendorId === product?.latestVendorDealPrice?.vendor.id
  const warningDeal = hasVendorAndProductHasDeal ? !matchVendor : false

  const styles = useStyles(hasVendorDealPrice, warningDeal)()

  const { amount, price, code } = watch()

  const warningDealPrice =
    hasVendorAndProductHasDeal && matchVendor && !!price ? product.latestVendorDealPrice?.price !== price : false

  const vendorCountry = !!options?.vendorCountry && options.vendorCountry !== 'ไทย' ? options.vendorCountry : 'ไทย'

  const handleSubmit = async () => {
    await trigger()
    const isExistCode = options?.existingProductCodeList?.find((_code) => _code === code)

    if (isExistCode) {
      setError('code', { type: 'isAlreadyExist', message: 'รหัสสินค้านี้ได้ถูกใช้แล้ว' })
    }

    if (Object.keys(errors).length > 0) {
      return
    } else {
      onDialogSubmit(watch())
      onClose && onClose({}, 'escapeKeyDown')
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps}>
      <DialogTitle>กรอกรายละเอียดสินค้า/บริการที่เลือก</DialogTitle>
      <DialogContent className="flex">
        <Grid container>
          <Grid item xs={12} md={3}>
            <div className="relative flex flex-col items-center pr-3">
              <Avatar variant="rounded" src={product?.imageUrl} style={{ height: '150px', width: '100%' }}>
                <Photo style={{ fontSize: 120 }} />
              </Avatar>
            </div>
          </Grid>

          <Grid item xs={12} md={9} className="md:pr-3">
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  {product?.code}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography variant="body2" color="textSecondary">
                  {vendorCountry !== 'ไทย' && !!product.nameEn ? (product.nameEn as string) : product.name}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} className="md:pr-3">
                <GeneralTextFieldForm
                  control={control}
                  name="code"
                  rules={{ required: 'กรุณากรอกรหัสสินค้า/บริการ สำหรับใบสั่งซื้อ' }}
                  type="text"
                  label="รหัสสินค้า/บริการ สำหรับใบสั่งซื้อ *"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="name"
                  rules={{ required: 'กรุณากรอกชื่อสินค้า/บริการ' }}
                  type="text"
                  label="ชื่อสินค้า/บริการ สำหรับใบสั่งซื้อ *"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} md={6} className="md:pr-3">
                <GeneralTextFieldForm
                  control={control}
                  name="amount"
                  rules={{ required: 'กรุณากรอกจำนวน' }}
                  type="number"
                  label="จำนวน *"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {vendorCountry !== 'ไทย' ? product.unitEn : product.unit}
                      </InputAdornment>
                    ),
                  }}
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <GeneralTextFieldForm
                  control={control}
                  name="price"
                  type="number"
                  error={warningDealPrice}
                  helperText={warningDealPrice ? 'ราคาไม่ตรงกับที่ตกลงกันล่าสุด' : ''}
                  rules={{ required: 'กรุณากรอกราคาต่อหน่วย' }}
                  InputProps={{
                    startAdornment: <InputAdornment position="start">{options?.currency || 'บาท'}</InputAdornment>,
                  }}
                  label="ราคาต่อหน่วย *"
                  defaultValue=""
                />
              </Grid>
              <Grid item xs={12} md={6}>
                {/* TODO: waiting for API */}
                <Typography variant="body2">คงเหลือในสต็อก xx อัน</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <div className="flex flex-col gap-2">
                  {amount && price && (
                    <div className="flex justify-end">
                      <Typography variant="body2" color="secondary">
                        ราคารวม {(amount * price).toLocaleString()} {options?.currency || 'บาท'}
                      </Typography>
                    </div>
                  )}
                  <div>
                    <Typography variant="body2" color="textSecondary">
                      ราคาจากผู้ขายล่าสุด
                    </Typography>
                    <FlexBox
                      gridGap={16}
                      justifyContent="space-between"
                      alignItems="center"
                      className={[styles.box, className].join(' ')}
                    >
                      {product.latestVendorDealPrice ? (
                        <>
                          <Avatar
                            variant="rounded"
                            src={product.latestVendorDealPrice?.vendor.profileImageUrl}
                            className={styles.avatar}
                          >
                            <Photo />
                          </Avatar>
                          <FlexBox flexDirection="column" className={styles.vendorNameBox}>
                            <Typography variant="body2" className={styles.vendorName}>
                              {product.latestVendorDealPrice?.vendor.name}
                            </Typography>
                            <Typography variant="body2" className={styles.vendorPrice}>
                              <div className="gap-2 flex">
                                ราคาดีล:
                                <span>
                                  {product.latestVendorDealPrice?.price.toLocaleString()}
                                  &nbsp;
                                  {product.latestVendorDealPrice?.vendor.currency.name}
                                </span>
                              </div>
                            </Typography>
                            <Typography variant="body2" className={styles.vendorPrice}>
                              <div className="gap-2 flex">
                                วันที่ดีล:
                                <span>{dateTimeFormat(product.latestVendorDealPrice?.recCreatedAt)}</span>
                              </div>
                            </Typography>
                          </FlexBox>
                        </>
                      ) : (
                        <Typography variant="body2" className={styles.noVendor}>
                          ไม่มีผู้ขายอ้างอิง
                        </Typography>
                      )}
                    </FlexBox>
                    {warningDeal && (
                      <div className="mt-2">
                        <Typography variant="body2" color="secondary">
                          ผู้ขายที่เลือกไม่ใช่ผู้ขายที่ดีลไว้ล่าสุด
                        </Typography>
                      </div>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body2">รายการใบสั่งซื้อของสินค้านี้</Typography>
          </Grid>

          <Grid item xs={12} className="pt-2">
            <Tabs
              style={{
                marginBottom: 4,
                boxShadow:
                  '0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)',
              }}
              value={tableType}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              onChange={(_, newValue) => setTableType(newValue)}
            >
              <Tab label="ประวัติใบสั่งซื้อของผู้ขายนี้" value={PurchaseOrderProductHistoryTableType.History} />
              <Tab label="ใบสั่งซื้อที่ถูกเปิดอยู่" value={PurchaseOrderProductHistoryTableType.Opening} />
            </Tabs>
          </Grid>

          <Grid item xs={12}>
            {tableType === PurchaseOrderProductHistoryTableType.History ? (
              <PurchaseOrderProductHistoryTable
                purchaseProductHistory={purchaseProductHistory}
                currency={options?.currency}
              />
            ) : (
              <PurchaseOrderProductOpeningTable
                purchaseProductHistory={purchaseProductHistory}
                currency={options?.currency}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className="purchase-product-creation-actions">
        <Button id="btn-cancel" color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
          ยกเลิก
        </Button>
        <Button color="primary" variant="contained" onClick={() => handleSubmit()}>
          ยืนยันข้อมูล
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default PurchaseOrderProductDialog
