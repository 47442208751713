import { Box, Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import numeral from 'numeral'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { FlexBox, SubTopic, Table } from '../../../../custom-components'
import { PurchaseProductType } from '../../../../enums'
import { calculatePurchaseOrderTotal, calculateVatAmount } from '../../../../functions'
import { formatNumber } from '../../../../functions/number-utils'
import { PurchaseProduct } from '../../../purchase-requisitions/create/PurchaseProductSelector/PurchaseProductSelector'
import PurchaseOrderCreateFormValues from '../PurchaseOrderCreateFormValues'
import PurchaseProductName from './PurchaseProductName'

interface SelectedPurchaseOrderProductTableData extends PurchaseProduct {
  id: number
  isNew: boolean
}

const SelectedPurchaseOrderProductInfoTable: React.FC = () => {
  // hook form
  const {
    watch,
    formState: { errors },
  } = useFormContext<PurchaseOrderCreateFormValues>()
  // data
  const { products: selectedProducts, discountAmount, vatIncluded, vendor } = watch()
  const parserProducts: SelectedPurchaseOrderProductTableData[] = selectedProducts.map((product) => {
    return {
      ...product,
      id: product.id || -1,
      isNew: !product.id,
    }
  })
  const total = selectedProducts.reduce<number>((sum, product) => {
    return sum + product.price! * product.amount
  }, 0)

  return (
    <Paper className="flex flex-col">
      <SubTopic className="pb-3">ข้อมูลสินค้า/บริการ</SubTopic>
      <Table<SelectedPurchaseOrderProductTableData>
        size="small"
        color="primary.dark"
        columns={[
          {
            name: 'ลำดับ',
            fixedWidth: 72,
            render: (_, index) => (
              <FlexBox justifyContent="space-around">
                <Typography color="textSecondary" variant="body2">
                  {index + 1}
                </Typography>
              </FlexBox>
            ),
          },
          {
            name: 'ชื่อสินค้า/บริการ',
            fixedWidth: 417,
            render: ({ imageUrl, name, code, isNew, type, typeId, price, latestVendorDealPrice }) => {
              const hasVendorAndProductHasDeal = !!vendor?.id && !!latestVendorDealPrice?.vendor.id
              const matchVendor = hasVendorAndProductHasDeal && vendor?.id === latestVendorDealPrice?.vendor.id
              const warningDeal = hasVendorAndProductHasDeal ? !matchVendor : false
              const warningDealPrice =
                hasVendorAndProductHasDeal && matchVendor && !!price ? latestVendorDealPrice?.price !== price : false
              const typeClassName =
                (type && type?.id === PurchaseProductType.Service) || typeId === PurchaseProductType.Service
                  ? 'purchase-product-header__type--service'
                  : 'purchase-product-header__type--product'
              let typeName = 'บริการ'

              if (!isNew) {
                typeName = type.name
              } else if (typeId !== PurchaseProductType.Service) {
                typeName = 'สินค้า'
              }

              return (
                <PurchaseProductName
                  imageUrl={imageUrl}
                  name={name}
                  code={code}
                  isNew={isNew}
                  typeName={typeName}
                  typeClassName={typeClassName}
                  warningDeal={warningDeal}
                  warningDealPrice={warningDealPrice}
                />
              )
            },
          },
          {
            name: 'ราคาที่เราขายต่อหน่วย',
            fixedWidth: 190,
            numeric: true,
            render: ({ salePrice }) => (salePrice ? formatNumber(salePrice) : '-'),
          },
          {
            name: 'จำนวน',
            fixedWidth: 235,
            numeric: true,
            format: '0,0',
            render: ({ amount }) => amount,
          },
          {
            name: 'ราคาต่อหน่วย',
            fixedWidth: 320,
            numeric: true,
            render: ({ price, salePrice }) => {
              if (!price) return '-'
              return (
                <FlexBox flexDirection="column">
                  <span>{formatNumber(price)}</span>
                  {!!salePrice && price > salePrice && (
                    <Box color="warning.main" clone>
                      <Typography variant="caption">ราคาเกินจากราคาขาย</Typography>
                    </Box>
                  )}
                </FlexBox>
              )
            },
          },
          {
            name: 'ราคารวม',
            fixedWidth: 235,
            numeric: true,
            format: '0,0.00',
            render: ({ price, amount }) => price && amount && price * amount,
          },
        ]}
        data={parserProducts || []}
        cleanSpace
        postCells={
          <>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell align="right">รวมเงิน</TableCell>
              <TableCell align="right">
                <Typography color={!!total ? 'textPrimary' : 'textSecondary'} variant="body2">
                  {!!total ? numeral(total).format('0,0.00') : 'คำนวณไม่ได้'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell align="right">ส่วนลด</TableCell>
              <TableCell align="right">
                <Typography color={!!discountAmount ? 'textPrimary' : 'textSecondary'} variant="body2">
                  {!!discountAmount ? numeral(discountAmount).format('0,0.00') : '-'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell align="right">ภาษีมูลค่าเพิ่ม 7 %</TableCell>
              <TableCell align="right">
                <Typography color={vatIncluded ? 'textPrimary' : 'textSecondary'} variant="body2">
                  {vatIncluded ? numeral(calculateVatAmount(total)).format('0,0.00') : '-'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={4} />
              <TableCell align="right">ยอดเงินสุทธิ</TableCell>
              <TableCell align="right">
                <Typography color="secondary" variant="body2">
                  {numeral(calculatePurchaseOrderTotal(total, discountAmount, vatIncluded)).format('0,0.00')}
                </Typography>
              </TableCell>
            </TableRow>
          </>
        }
        pagination={false}
      />
      {!!errors.products && <Typography color="error">{errors.products.message}</Typography>}
    </Paper>
  )
}

export default SelectedPurchaseOrderProductInfoTable
