import {
  SaleOrderProfile,
  createInvoice as createInvoiceAPI,
  InvoiceCreationRequest,
  InvoiceItem,
  PriceBreakdownItem,
  SaleOrderProduct,
} from '../../../../../../api/smartbestbuys-api'
import { calculateExcludingVat } from '../../../../../../functions'
import { InvoiceCreationFormValues } from './InvoiceCreationDialog'

export const createInvoice = (saleOrderProfile: SaleOrderProfile, values: InvoiceCreationFormValues) => {
  const priceBreakdowns = getInvoicePriceBreakdowns(saleOrderProfile, values)
  const request: InvoiceCreationRequest = {
    customerId: saleOrderProfile.customer.id,
    total: priceBreakdowns[priceBreakdowns.length - 1].amount,
    percentAmount: values.percentAmount,
    items: saleOrderProfile.products.map(mapToItem),
    priceBreakdowns: priceBreakdowns.map(
      (p) =>
        ({
          label: p.label,
          amount: p.amount,
        } as PriceBreakdownItem),
    ),
    remark: values.remark,
    soPaymentTransactionId: values?.soPaymentTransactionId,
  }

  return createInvoiceAPI(saleOrderProfile.id, request)
}

const mapToItem = (product: SaleOrderProduct): InvoiceItem => {
  return {
    name: product.nameLocal,
    code: product.code,
    imageUrl: product.imageUrl,
    amount: product.amount,
    pricePerEach: product.price,
    total: product.amount * product.price,
  }
}

export const calculateActualTotal = (saleOrderProfile: SaleOrderProfile) => {
  const { productTotal, discountAmount, estimatedShippingFee, isShippingFeeIncludingVat } = saleOrderProfile
  const discount = discountAmount || 0

  let shippingFee = estimatedShippingFee || 0
  if (isShippingFeeIncludingVat) {
    shippingFee = calculateExcludingVat(shippingFee)
  }

  return productTotal + shippingFee - discount
}

export const getInvoicePriceBreakdowns = (saleOrderProfile: SaleOrderProfile, values: InvoiceCreationFormValues) => {
  const { amount: invoiceAmount = 0, depositAmount, paymentCondition } = values
  const { productTotal, discountAmount, estimatedShippingFee, isShippingFeeIncludingVat, vatIncluded } =
    saleOrderProfile
  const discount = discountAmount || 0

  let shippingFee = estimatedShippingFee || 0
  if (isShippingFeeIncludingVat) {
    shippingFee = calculateExcludingVat(shippingFee)
  }

  const actualTotal = productTotal + shippingFee - discount

  const vatAmount = vatIncluded ? invoiceAmount * 0.07 : 0

  const isPartialAmount = paymentCondition === 2

  return [
    { label: 'รวมเงิน', amount: productTotal },
    ...(shippingFee ? [{ label: 'ค่าจัดส่ง', amount: shippingFee }] : []),
    ...(discount ? [{ label: 'ส่วนลด', amount: discount }] : []),
    { label: 'หักยอดเงินมัดจำ', amount: depositAmount },
    { label: 'มูลค่าคงเหลือ', amount: actualTotal - depositAmount },
    {
      label: 'ยอดเงินที่ต้องชำระ',
      amount: invoiceAmount,
      input: isPartialAmount ? 'amount' : undefined,
      options: [30, 50, 70],
    },
    { label: 'ภาษีมูลค่าเพิ่ม 7 %', amount: vatAmount },
    { label: 'ยอดเงินที่ต้องชำระรวมภาษีมูลค่าเพิ่ม 7%', amount: invoiceAmount + vatAmount },
  ]
}

export const getPaymentCondition = (saleOrderProfile: SaleOrderProfile) => {
  const { documents } = saleOrderProfile
  const invoices = (documents && documents.filter((d) => d.type.id === 101)) || []
  const validInvoices = invoices.filter((invoice) => invoice.status.id !== 3)
  const isNotFirst = validInvoices?.length > 0

  if (isNotFirst) {
    return [
      { id: 1, name: 'เต็มจำนวน' },
      { id: 2, name: 'ไม่เต็มจำนวน' },
      { id: 3, name: 'ชำระยอดที่เหลือ' },
    ]
  }

  return [
    { id: 1, name: 'เต็มจำนวน' },
    { id: 2, name: 'ไม่เต็มจำนวน' },
  ]
}
