import { DateTime } from 'luxon'
import { errorHandler, errorSingleHandler } from '../../middleware'
import { SaleOrderProfile } from './get-sale-order-profile-api'
import {
  GetSaleOrderTableDataRequestQueryParam,
  ISaleOrderDailyPaymentTableData,
  ISaleOrderTableData,
  ISalesGoalDaily,
  SaleOrderDocumentInfo,
  SaleOrderPartialUpdateRequest,
  SaleOrderTakeNoteRequest,
  SetSalesGoalDailyRequestBody,
} from './sale-types'
import { DateFilterParams } from '../types'
import smartAuthAPI from '../smartbestbuys-api'
import { VoidResponse } from '../interfaces'

export const getSaleOrderDocumentsAPI = errorHandler<SaleOrderDocumentInfo[]>((id: number) => {
  return smartAuthAPI.get(`/sale-orders/${id}/documents`)
})

export const getSaleOrderDailyPatmentAPI = errorHandler<ISaleOrderDailyPaymentTableData[]>(
  async (request: { paymentDate: DateTime }) => {
    return smartAuthAPI.get('/sale-orders/v1/sale-orders/payment-daily-report', {
      params: { date: request.paymentDate.toFormat('yyyy-MM-dd') },
    })
  },
)

export const partialUpdateSaleOrder = errorSingleHandler<SaleOrderProfile>(
  async (id: number, request: SaleOrderPartialUpdateRequest) => {
    return smartAuthAPI.patch(`sale-orders/${id}/partial-update`, request)
  },
)

export const getSaleOrderTableDataAPI = errorHandler<ISaleOrderTableData>(
  async (queryParams: GetSaleOrderTableDataRequestQueryParam) => {
    return await smartAuthAPI.get(`/sale-orders/table-data`, {
      params: queryParams,
    })
  },
)

export const saleOrderTakeNoteAPI = errorSingleHandler<{ success: boolean }>(
  async (id: number, request: SaleOrderTakeNoteRequest) => {
    return smartAuthAPI.post(`sale-orders/${id}/take-note`, request)
  },
)

export const getSalesGoalDailyAPI = errorHandler<ISalesGoalDaily[]>(async (params: DateFilterParams) => {
  return smartAuthAPI.get('/api/v2/sale-orders/sales-goal-daily', {
    params: { startDate: params.startDate, endDate: params.endDate },
  })
})

export const setSalesGoalDailyAPI = errorHandler<{ success: boolean }>(
  async (request: SetSalesGoalDailyRequestBody) => {
    return smartAuthAPI.post(`api/v2/sale-orders/set-sales-goal-daily`, request)
  },
)

export const approveSOPaymentTransactionAPIV2 = errorSingleHandler(async (id: number, transactionId: number) => {
  const response = await smartAuthAPI.post<VoidResponse>(`api/v2/sale-orders/${id}/payments/${transactionId}/approve`)

  return response
})
