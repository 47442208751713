import {
  Button,
  Chip,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Theme,
  Typography,
  createStyles,
  makeStyles,
  useTheme,
} from '@material-ui/core'
import { useContext, useEffect, useState } from 'react'
import { RootContext } from '../../../..'
import { PageName, SOPaymentTransactionStatus } from '../../../../enums'
import { useSOPaymentTransactionTableData } from '../../../../hooks/sales/useSOPaymentTransactionTableData'
import {
  Loading,
  PaymentTransactionStatusChip,
  SOPaymentTransactionDialog,
  StatementVerifiedSignal,
  StatementVerifyingSignal,
} from '../../../../shared-components'
import { GeneralTextField, Link, Table, Topic, withLayout } from '../../../../custom-components'
import { ISOPaymentTransactionTableData_Data } from '../../../../api/smartbestbuys-api'
import { dateFormat, dateTimeFormat } from '../../../../functions'
import { DocumentType } from '../../../../enums'
import SOPaymentTransactionConfirmWithholdingDocumentDialog from './SOPaymentTransactionConfirmWithholdingDocumentDialog'
import { getUserPermissionInfo } from '../../../../utils/permission'
import { PermissionEnum } from '../../../../enums/PermissionEnum'
import { WithholdingTaxDocumentReceivedToggle } from '../../../../shared-components/Toggle'

/* eslint-disable @typescript-eslint/no-unused-vars */
const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      maxHeight: 440,
    },
    formControl: {
      width: '100%',
    },
    selectControl: {
      border: '1px solid grey',
      borderRadius: '4px',
      padding: '4px',
    },
    selectLabel: {
      paddingLeft: '4px',
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
  }),
)

function getStyles(value: number, filterTier: number[], theme: Theme) {
  return {
    fontWeight: filterTier.find((row) => row === value)
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
  }
}

const SOPaymentTransactionTableDataPage = () => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.SO_PAYMENT)

  const theme = useTheme()
  const classes = useStyles()

  const tableData = useSOPaymentTransactionTableData()

  // state
  const [selected, setSelected] = useState<ISOPaymentTransactionTableData_Data | undefined>(undefined)
  const [soData, setSOData] = useState<ISOPaymentTransactionTableData_Data[]>([])
  const [selectedConfirmWithholdingDocumentReceived, setSelectedConfirmWithholdingDocumentReceived] = useState<
    { saleOrderId: number; transactionId: number; code: string } | undefined
  >(undefined)

  useEffect(() => {
    if (tableData?.data) setSOData(tableData?.data)
  }, [tableData?.data])

  const statusOptions = [
    { value: 1, name: 'รอตรวจสอบ' },
    { value: 2, name: 'อนุมัติ' },
    { value: 3, name: 'ไม่อนุมัติ' },
    { value: 4, name: 'ยกเลิก' },
  ]

  const paymentMethodOptions = [
    { value: 1, name: 'เงินสด' },
    { value: 2, name: 'โอน' },
    { value: 3, name: 'บัตรเครดิต / เดบิต' },
    { value: 4, name: 'เช็ค' },
  ]

  if (!tableData) return <Loading />
  const {
    data,
    paginationInfo,
    pendingSearch,
    handlePageChange,
    handleRowPerPageChange,
    handleSearch,
    handleSearchParamChange,
  } = tableData

  const handleSubmit = (e: any) => {
    e.preventDefault()
    handleSearch()
  }

  const handleSelectTransaction = (transaction: ISOPaymentTransactionTableData_Data) => setSelected(transaction)
  const handleCloseDialog = () => setSelected(undefined)

  const handleOnCheckPendingVerifyStatement = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('pendingVerifyStatement', event.target.checked ? 'true' : 'false')
  }

  const handleOnCheckHasWithholdingTax = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('hasWithholdingTax', event.target.checked ? 'true' : undefined)
  }

  const handleOnCheckWithholdingTaxDocumentReceived = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('withholdingTaxDocumentReceived', event.target.checked ? 'false' : undefined)
  }

  const handleOnCheckTaxInvoice = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchParamChange('hasTaxInvoice', event.target.checked ? 'false' : undefined)
  }

  const writePermission = getUserPermissionInfo(PermissionEnum.Finance_SOPayment_Write)

  return (
    <>
      {selected && (
        <SOPaymentTransactionDialog
          open={!!selected}
          onClose={handleCloseDialog}
          saleOrderId={selected.saleOrderInfo.id}
          soStatusId={selected.saleOrderInfo.status.id}
          transaction={selected}
          total={selected.saleOrderInfo.total}
          onConfirm={(updatedData) => {
            if (!updatedData) {
              if (!!tableData?.refetch) tableData.refetch()
            } else {
              let newData = data
              const findIndex = newData.findIndex((d) => d.id === updatedData.id)
              if (findIndex !== -1) {
                newData[findIndex].status = updatedData.status
                setSOData([...newData])
              }
            }
            setSelected(undefined)
          }}
          onVerify={(updatedData) => {
            let newData = data
            const findIndex = newData.findIndex((d) => d.id === updatedData.id)
            if (findIndex !== -1) {
              newData[findIndex].statementVerifiedAt = updatedData.statementVerifiedAt
              setSOData([...newData])
            }
            setSelected(undefined)
          }}
        />
      )}
      {selectedConfirmWithholdingDocumentReceived && (
        <SOPaymentTransactionConfirmWithholdingDocumentDialog
          parameter={selectedConfirmWithholdingDocumentReceived}
          open={!!selectedConfirmWithholdingDocumentReceived}
          onSuccess={() => {
            setSelectedConfirmWithholdingDocumentReceived(undefined)
            if (!!tableData.refetch) tableData.refetch()
          }}
          onClose={() => {
            setSelectedConfirmWithholdingDocumentReceived(undefined)
          }}
        />
      )}
      <Paper>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Topic>รายการรายได้</Topic>
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="text"
                    name="name"
                    onChange={(e) => handleSearchParamChange('code', e.target.value)}
                    label="เลขที่รายได้"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="text"
                    name="saleOrderCode"
                    onChange={(e) => handleSearchParamChange('saleOrderCode', e.target.value)}
                    label="เลขที่ใบสั่งขาย"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="text"
                    name="phoneNumber"
                    onChange={(e) => handleSearchParamChange('customerName', e.target.value)}
                    label="ชื่อลูกค้า"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="text"
                    name="referenceNumber"
                    onChange={(e) => handleSearchParamChange('phoneNumber', e.target.value)}
                    label="เบอร์โทรศัพท์"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="date"
                    name="startDate"
                    onChange={(e) =>
                      handleSearchParamChange('startDate', e.target.value ? new Date(e.target.value) : undefined)
                    }
                    InputLabelProps={{ shrink: true }}
                    label="วันที่บันทึก (ตั้งแต่)"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="date"
                    name="endDate"
                    onChange={(e) =>
                      handleSearchParamChange('endDate', e.target.value ? new Date(e.target.value) : undefined)
                    }
                    InputLabelProps={{ shrink: true }}
                    label="วันที่บันทึก (ถึง)"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="tier-select-label" style={{ paddingLeft: 16, paddingTop: 4 }}>
                      สถานะรายได้
                    </InputLabel>
                    <Select
                      labelId="status-select-label"
                      id="status-select"
                      multiple
                      value={pendingSearch.statusIds ?? []}
                      onChange={({ target: { value } }) => {
                        handleSearchParamChange('statusIds', value as number[] | undefined)
                      }}
                      input={<Input />}
                      MenuProps={MenuProps}
                      className={classes.selectControl}
                      name="statusIds"
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {(selected as number[]).map((value) => (
                            <Chip
                              className={classes.chip}
                              key={value}
                              label={statusOptions.find((row) => row.value === value)?.name}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {statusOptions.map((opt) => (
                        <MenuItem
                          key={opt.name}
                          value={opt.value}
                          style={getStyles(opt.value, pendingSearch.statusIds as number[], theme)}
                        >
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="payment-method-select-label" style={{ paddingLeft: 16, paddingTop: 4 }}>
                      วิธีการชำระเงิน
                    </InputLabel>
                    <Select
                      labelId="payment-method-select-label"
                      id="payment-method-select"
                      multiple
                      value={pendingSearch.paymentMethodIds ?? []}
                      onChange={({ target: { value } }) => {
                        handleSearchParamChange('paymentMethodIds', value as number[] | undefined)
                      }}
                      input={<Input />}
                      MenuProps={MenuProps}
                      className={classes.selectControl}
                      name="paymentMethodIds"
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {(selected as number[]).map((value) => (
                            <Chip
                              className={classes.chip}
                              key={value}
                              label={paymentMethodOptions.find((row) => row.value === value)?.name}
                            />
                          ))}
                        </div>
                      )}
                    >
                      {paymentMethodOptions.map((opt) => (
                        <MenuItem
                          key={opt.name}
                          value={opt.value}
                          style={getStyles(opt.value, pendingSearch.statusIds as number[], theme)}
                        >
                          {opt.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormGroup row className="mt-2">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pendingSearch.pendingVerifyStatement === 'true'}
                          onChange={handleOnCheckPendingVerifyStatement}
                        />
                      }
                      label="รอตรวจสอบ statement"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="date"
                    name="statementStartDate"
                    onChange={(e) =>
                      handleSearchParamChange(
                        'statementStartDate',
                        e.target.value ? new Date(e.target.value) : undefined,
                      )
                    }
                    InputLabelProps={{ shrink: true }}
                    label="วันที่รับเงิน (ตั้งแต่)"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <GeneralTextField
                    type="date"
                    name="statementEndDate"
                    onChange={(e) =>
                      handleSearchParamChange('statementEndDate', e.target.value ? new Date(e.target.value) : undefined)
                    }
                    InputLabelProps={{ shrink: true }}
                    label="วันที่รับเงิน (ถึง)"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <FormGroup row className="mt-1">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pendingSearch.hasWithholdingTax === 'true'}
                          onChange={handleOnCheckHasWithholdingTax}
                        />
                      }
                      label="หัก ณ ที่จ่าย"
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={pendingSearch.hasTaxInvoice === 'false'}
                          onChange={handleOnCheckTaxInvoice}
                        />
                      }
                      label="ไม่มีใบกำกับภาษีอ้างอิง"
                    />
                  </FormGroup>
                </Grid>

                {pendingSearch.hasWithholdingTax === 'true' && (
                  <Grid item xs={12} sm={4}>
                    <FormGroup row className="mt-1">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={pendingSearch.withholdingTaxDocumentReceived === 'false'}
                            onChange={handleOnCheckWithholdingTaxDocumentReceived}
                          />
                        }
                        label="ยังไม่ได้รับเอกสาร หัก ณ ที่จ่าย"
                      />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
              <Grid container spacing={1} style={{ marginTop: '16px', marginBottom: '16px' }}>
                <Grid item xs={12} sm={10}></Grid>
                <Grid item xs={12} sm={2}>
                  <Button size="medium" variant="contained" color="primary" fullWidth className="button" type="submit">
                    ค้นหา
                  </Button>
                </Grid>
              </Grid>
            </form>
            <Table<ISOPaymentTransactionTableData_Data>
              color="primary.dark"
              columns={[
                {
                  name: '',
                  render: ({ withholdingAmount, withholdingTaxDocumentReceived, status, saleOrderInfo, code, id }) => {
                    const tag = withholdingTaxDocumentReceived ? 'success' : 'warning'

                    const isShowToggle =
                      writePermission.hasPermission &&
                      !!withholdingAmount &&
                      ![SOPaymentTransactionStatus.CANCELLED, SOPaymentTransactionStatus.DECLINED].includes(status.id)

                    if (!isShowToggle) return <></>

                    return (
                      <WithholdingTaxDocumentReceivedToggle
                        isActive={withholdingTaxDocumentReceived}
                        disabled={withholdingTaxDocumentReceived}
                        onClick={() =>
                          setSelectedConfirmWithholdingDocumentReceived({
                            saleOrderId: saleOrderInfo.id,
                            transactionId: id,
                            code,
                          })
                        }
                      />
                    )
                  },
                },
                {
                  name: 'สถานะรายได้',
                  render: ({ status }) => <PaymentTransactionStatusChip status={status.id} label={status.name} />,
                },
                {
                  name: 'ตรวจสอบ Statement',
                  render: (transaction) => {
                    const isApproved = transaction.status.id === SOPaymentTransactionStatus.APPROVED
                    const isVerifiedStatement = !!transaction.statementVerifiedAt

                    if (isApproved) {
                      return isVerifiedStatement ? <StatementVerifiedSignal /> : <StatementVerifyingSignal />
                    } else {
                      return <></>
                    }
                  },
                  fixedWidth: 100,
                },
                {
                  name: 'เลขที่การรับรายได้',
                  render: (transaction) => (
                    <>
                      {transaction.status.id === SOPaymentTransactionStatus.PENDING ||
                      !transaction.paymentTransactionId ? (
                        <Button color="primary" onClick={() => handleSelectTransaction(transaction)}>
                          {transaction.code}
                        </Button>
                      ) : (
                        <Link
                          href={`${process.env.REACT_APP_BACKOFFICE_V2}/finance/payment-transactions/${transaction.paymentTransactionId}`}
                          target="_blank"
                        >
                          <Button color="primary">{transaction.code}</Button>
                        </Link>
                      )}
                    </>
                  ),
                },
                {
                  name: 'เลขที่ใบสั่งขาย',
                  render: ({ saleOrderInfo }) => (
                    <Link color="primary" to={`/sale-orders/${saleOrderInfo.id}`}>
                      <Button color="secondary">{saleOrderInfo.code}</Button>
                    </Link>
                  ),
                },
                {
                  name: 'เอกสารที่เกี่ยวข้อง',
                  render: ({ referenceDocuments }) =>
                    !!referenceDocuments && referenceDocuments.length > 0 ? (
                      referenceDocuments.map((ref) => {
                        return (
                          <div id={ref.code}>
                            <Typography variant="body2" color="secondary">
                              {getDocumentTypeName(ref.typeId)} {ref.code}
                            </Typography>
                          </div>
                        )
                      })
                    ) : (
                      <Typography color="textSecondary">-</Typography>
                    ),
                },
                {
                  name: 'วันที่บันทึก',
                  render: ({ recCreatedAt }) => dateFormat(recCreatedAt),
                },
                {
                  name: 'วันและเวลาที่เงินเข้าบัญชี',
                  render: ({ statementTimestamp, paymentTimestamp }) =>
                    statementTimestamp
                      ? dateTimeFormat(statementTimestamp)
                      : paymentTimestamp
                      ? dateTimeFormat(paymentTimestamp)
                      : '-',
                },
                { name: 'ชื่อลูกค้า', render: ({ customerInfo }) => customerInfo.name },
                { name: 'เบอร์โทรศัพท์', render: ({ customerInfo }) => customerInfo.phoneNumber },
                { name: 'วิธีชำระเงิน', render: ({ paymentMethod }) => paymentMethod.name },
                { name: 'ยอดรวมสุทธิ', dataIndex: 'amount', numeric: true, format: '0,0.00' },
                { name: 'ผู้สร้าง', dataIndex: 'recCreatedBy' },
              ]}
              data={soData}
              size="small"
              paginationInfo={paginationInfo}
              onPageChange={handlePageChange}
              onRowPerPageChange={handleRowPerPageChange}
              isLoading={tableData.isLoading}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}

export default withLayout(SOPaymentTransactionTableDataPage, true)

const getDocumentTypeName = (documentTypeId: number): string | undefined => {
  switch (documentTypeId) {
    case DocumentType.Invoice:
      return 'ใบแจ้งหนี้'
    case DocumentType.TaxInvoice:
      return 'ใบกำกับภาษี'
    case DocumentType.CreditNote:
      return 'ใบลดหนี้'
    default:
      return undefined
  }
}
